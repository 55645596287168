const en = {
  tutelage: {
    list: {
      target: 'Macchine',
    },
    taskCreation: {
      target: {
        title: 'Macchine',
        asset: 'Macchine specifiche',
        groups: 'Gruppi di macchine',
        checkbox: {
          label: 'Applica {{value}} sulle macchine',
        },
        radio: {
          specific: 'Crea {{value}} specifica per ogni macchina coinvolto',
          unique: 'Crea {{value}} unica per tutti le macchine coinvolte',
        },
      },
    },
    detail: {
      target: 'Macchine',
    },
  },
}

export default en