const it = {
  tutelage: {
    list: {
      target: 'Macchine',
    },
    taskCreation: {
      target: {
        title: 'Macchine',
        asset: {
          label: 'macchine',
          radio: 'Macchine specifiche',
          sel: 'macchina selezionata',
          sels: 'macchine selezionate',
          found: '{{value}} su {{totalValue}} macchine trovate {{valueSpecific}}'
        },
        group: {
          radio: 'Gruppi di macchine',
          sel: 'gruppo di macchine selezionato',
          sels: 'gruppi di macchine selezionate',
          found: '{{value}} su {{totalValue}} gruppi di macchine trovate {{valueSpecific}}'
        },
        checkbox: {
          label: 'Applica {{value}} sulle macchine',
        },
        radio: {
          specific: 'Crea {{value}} specifica per ogni macchina coinvolto',
          unique: 'Crea {{value}} unica per tutti le macchine coinvolte',
        },
      },
    },
    detail: {
      target: 'Macchine',
    },
  },
}

export default it